const audioContext = new (window.AudioContext || window.webkitAudioContext)();
const sounds = {};

export const loadSound = async (name, url) => {
  console.log(name,url)
  debugger
  const response = await fetch(url);
  const arrayBuffer = await response.arrayBuffer();
  try {
    const audioBuffer = await new Promise((resolve, reject) => {
        audioContext.decodeAudioData(arrayBuffer, resolve, reject);
    });
    sounds[name] = audioBuffer;
    console.log("Audio buffer loaded successfully");
    } catch (error) {
      console.error("Error decoding audio data: ", error);
    }  
};

export const playSound = (name) => {
  if (!sounds[name]) return; // If the sound is not loaded, don't play

  const soundSource = audioContext.createBufferSource();
  soundSource.buffer = sounds[name];
  soundSource.connect(audioContext.destination);
  soundSource.start();
};
