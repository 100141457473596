import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input} from 'reactstrap';
import './Players.css'; // Adjust the path as per your directory structure
const backendUrl = process.env.REACT_APP_BACKEND_URL || 'https://poker-manager-backend-325cb430abd3.herokuapp.com';


const PlayersList = () => {
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modal, setModal] = useState(false); // State to control modal visibility
  const [newPlayerName, setNewPlayerName] = useState(''); // State to store new player's name
  const [newPlayerEmail, setNewPlayerEmail] = useState(''); // State to store new player's email

  const toggleModal = () => setModal(!modal);

  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const response = await axios.get(`${backendUrl}/users`);
        setPlayers(response.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchPlayers();
  }, []);

  const handlePlayerCreation = async () => {
    try {
      const response = await axios.post(`${backendUrl}/users`, {
        name: newPlayerName,
        email: newPlayerEmail,
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        }
      });
      setPlayers((prevPlayers) => [...prevPlayers, response.data]);
      toggleModal(); // Close the modal
      setNewPlayerName(''); // Reset the player name state
      setNewPlayerEmail(''); // Reset the player email state
    } catch (err) {
      console.error('Error creating player:', err);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container mt-5">
      <div className ="card">
        <Button color="primary" onClick={toggleModal}>Create Player</Button>
        <Modal isOpen={modal} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Create a New Player</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="playerName">Player Name:</Label>
                <Input type="text" value={newPlayerName}
                  onChange={(e) => setNewPlayerName(e.target.value)} id="playerName" />
              </FormGroup>
              <FormGroup>
                <Label for="playerEmail">Player Email:</Label>
                <Input type="email" value={newPlayerEmail}
                  onChange={(e) => setNewPlayerEmail(e.target.value)} id="playerEmail" />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handlePlayerCreation}>Submit</Button>{' '}
            <Button color="secondary" onClick={toggleModal}>Cancel</Button>
          </ModalFooter>
        </Modal>

        <div className="list-group">
          {players.map((player) => (
            <a key={player.id}
              href="#" className="list-group-item list-group-item-action flex-column align-items-start">
              <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">{player.name}</h5>
              </div>
              <small>{player.email}</small>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PlayersList;
