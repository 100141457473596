import React, {useState, useEffect} from 'react';
import {loadSound, playSound} from './../../helpers/audioHelper';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './Timer.css';


const Timer = () => {
  const [time, setTime] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [soundsLoaded, setSoundsLoaded] = useState(false);

  const textArray = [
    '10/20', '20/40', '40/50', '50/100', '100/200',
    '200/400', '400/800', '500/1000', '1000/2000',
  ];
  const soundFiles = [
    'start.mp3', 
    '20-40.mp3', 
    '40-50.mp3', 
    '50-100.mp3',
    '100-200.mp3', 
    '200-400.mp3', 
    '400-800.mp3',
    '500-1000.mp3',
    '1000-2000.mp3'
  ];

  useEffect(() => {
    const loadAllSounds = async () => {
        await Promise.all(soundFiles.map((file, index) => loadSound(`sound${index}`, file)));
        setSoundsLoaded(true);
    };
    loadAllSounds();
  }, []); 

  useEffect(() => {
    let interval;
    if (isActive && soundsLoaded) {
      if (time === 0) {
        playSound('sound0'); // Play start sound when timer starts
      }
      interval = setInterval(() => {
        setTime((prevTime) => {
          const nextTime = prevTime + 1;
          // Check if it's a multiple of 10 minutes
          if (Math.floor(nextTime / 60) % 10 === 0 && nextTime % 60 === 0) {
            const nextIndex = Math.floor((nextTime / 60) / 10) % textArray.length;
            setCurrentIndex(nextIndex);
            if (currentIndex !== nextIndex) {
              playSound(`sound${nextIndex}`);
            }
            setCurrentIndex(nextIndex);
          }
          return nextTime;
        });
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isActive, currentIndex, soundsLoaded]); // Dependencies

  const resetTimer = () => {
    setTime(0);
    setCurrentIndex(0);
    setIsActive(false);
  };
  return (
    <div className="container-fluid timer-container d-flex flex-column vh-70">
      <div className="card text-center shadow-lg">
        <div className="card-body">
          <div className="text-display-1 flex-fill">
            <div className="huge-display">
              {Math.floor(time / 60).toString().padStart(2, '0')}:
              {(time % 60).toString().padStart(2, '0')}
            </div>
          </div>
          <div className="text-display-2 flex-fill">
            <div className="single-blind-display">
              {textArray[currentIndex]}
            </div>
          </div>
          <div className="buttons-row d-flex justify-content-center mt-4">
            <button className="custom-btn btn-start mx-2" onClick={() => setIsActive(!isActive)}>
              <i className={`bi ${isActive ? 'bi-pause-fill' : 'bi-play-fill'}`}></i>
            </button>
            <button className="custom-btn btn-reset mx-2" onClick={resetTimer}>
              <i className="bi bi-arrow-repeat"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timer;

