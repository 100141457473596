import React, {useState} from 'react';
import axios from 'axios';
import {Button, Form, FormGroup, Label, Input} from 'reactstrap';
const backendUrl = process.env.REACT_APP_BACKEND_URL || 'https://poker-manager-backend-325cb430abd3.herokuapp.com';

const Login = () => {
  const [email, setEmail] = useState('');
  const [nickname, setName] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${backendUrl}/auth/login`, {
        email, // Send as identity; backend should handle whether it's a nickname or email
        password,
        nickname,
      });
      if (response.status == 200 && response.data) {
        localStorage.setItem('jwtToken', response.data);
      }
      // On successful login, store the token (if using JWT) and redirect to a protected route or dashboard
    } catch (error) {
      console.error('Error logging in:', error);
    }
  };

  return (
    <div className="container mt-5">
      <div className="card">
        <Form>
          <FormGroup>
            <Label for="Email">Email:</Label>
            <Input type="text" value={email} onChange={(e) => setEmail(e.target.value)} id="email" />
          </FormGroup>
          <FormGroup>
            <Label for="nickname">Nickname:</Label>
            <Input type="text" value={nickname} onChange={(e) => setName(e.target.value)} id="nickname" />
          </FormGroup>
          <FormGroup>
            <Label for="password">Password:</Label>
            <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} id="password" />
          </FormGroup>
          <Button color="primary" className="mt-3" onClick={handleSubmit}>
            Login
          </Button>
        </Form>
      </div>
    </div>
  );
};


export default Login;
