import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Button, Form, FormGroup, Label, Input} from 'reactstrap';
const backendUrl = process.env.REACT_APP_BACKEND_URL || 'https://poker-manager-backend-325cb430abd3.herokuapp.com';

const ProfilesPage = () => {
  // New state to manage fetched profiles
  const [profiles, setProfiles] = useState([]);
  const [timer, setTimer] = useState(0);
  const [blinds, setBlinds] = useState(['10/20']);

  // Fetching profiles from the backend
  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const response = await axios.get(`${backendUrl}/profiles`);
        setProfiles(response.data);
      } catch (error) {
        console.error('Error fetching profiles:', error);
      }
    };

    fetchProfiles();
  }, []);

  // Existing handlers for the profile creation form
  const handleTimerChange = (e) => {
    setTimer(Number(e.target.value));
  };

  const handleBlindChange = (index, value) => {
    const newBlinds = [...blinds];
    newBlinds[index] = value;
    setBlinds(newBlinds);
  };

  const addNewBlindField = () => {
    setBlinds([...blinds, '']);
  };

  const handleSubmit = async () => {
    const payload = {
      timer,
      blinds,
      userId: 271, // This is static for now, you might want to adjust this based on your requirements
    };

    try {
      const response = await axios.post(`${backendUrl}/profiles/`, payload);
      setProfiles((prevProfiles) => [...prevProfiles, response.data]);
      // Resetting the form fields
      setTimer('');
      setBlinds(['']);
    } catch (error) {
      console.error('Error sending request:', error);
    }
  };

  return (
    <div className="container mt-5">
      <div className="card">
        <div className="row">
          {/* Left Column: Display profiles */}
          <div className="col-md-6">
            {profiles.map((profile) => (
              <div key={profile.id} className="card mb-4">
                <div className="card-body">
                  <h5 className="card-title">Timer: {profile.timer}</h5>
                  {profile.blinds.map((blind, index) => (
                    <p key={index} className="card-text">Blind {index + 1}: {blind}</p>
                  ))}
                </div>
              </div>
            ))}
          </div>

          {/* Right Column: Profile Creation */}
          <div className="col-md-6">
            <Form>
              <FormGroup>
                <Label for="timer">Timer:</Label>
                <Input type="number" value={timer} onChange={handleTimerChange} id="timer" />
              </FormGroup>

              {blinds.map((blind, index) => (
                <FormGroup key={index}>
                  <Label for={`blind-${index}`}>Blind {index + 1}:</Label>
                  <Input type="text" value={blind} onChange={(e) => handleBlindChange(index, e.target.value)}
                    id={`blind-${index}`} />
                </FormGroup>
              ))}

              <Button onClick={addNewBlindField}>Add another blind</Button>
              <Button color="primary" className="mt-3" onClick={handleSubmit}>Submit</Button>
            </Form>
          </div>

        </div>
      </div>
    </div>
  );
};

export default ProfilesPage;
