import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Header from './components/Header';
// import Footer from './components/common/Footer';
import Timer from './components/game/Timer';
import About from './components/pages/About';
import Players from './components/pages/Players';
import Profiles from './components/pages/Profiles';
import Login from './components/pages/Login';
import Register from './components/pages/Register';


import './App.css';


const App = () => {
  return (
      <Router>
        <div id="app" className="bodyBackground">
          <Header />
          <div className="main-layout">
            <Routes>
              <Route path="/" element={<Timer />} />
              <Route path="/about" element={<About />} />
              <Route path="/profiles" element={<Profiles />} />
              <Route path="/players" element={<Players />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              {/* Add more Routes here as needed */}
            </Routes>
          </div>
        </div>
      </Router>
  );
};

export default App;
