import React, {useState} from 'react';
import axios from 'axios';
import {Button, Form, FormGroup, Label, Input} from 'reactstrap';
const backendUrl = process.env.REACT_APP_BACKEND_URL || 'https://poker-manager-backend-325cb430abd3.herokuapp.com';

const Register = () => {
  const [nickname, setNickname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');


  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${backendUrl}/users`, {
        name,
        nickname,
        email,
        password,
      });
      console.log(response.data);
      // Ideally, upon successful registration, redirect to login or dashboard
    } catch (error) {
      console.error('Error registering:', error);
    }
  };

  return (
    <div className="container mt-5">
      <div className="card">
        <Form>
          <FormGroup>
            <Label for="name">Name:</Label>
            <Input type="text" value={name} onChange={(e) => setName(e.target.value)} id="name" />
          </FormGroup>
          <FormGroup>
            <Label for="nickname">Nickname:</Label>
            <Input type="text" value={nickname} onChange={(e) => setNickname(e.target.value)} id="nickname" />
          </FormGroup>
          <FormGroup>
            <Label for="email">Email:</Label>
            <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} id="email" />
          </FormGroup>
          <FormGroup>
            <Label for="password">Password:</Label>
            <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} id="password" />
          </FormGroup>
          <Button color="primary" className="mt-3" onClick={handleSubmit}>
            Register
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default Register;
